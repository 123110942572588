<template>
  <div class="page-container">
    <HeaderComp></HeaderComp>

    <div class="content">
      <!-- <Transition name="slide-fade"> -->
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      <!-- </Transition> -->
    </div>

    <FooterComp v-if="shouldRender"></FooterComp>
  </div>
</template>

<script lang="ts" scoped>
import HeaderComp from './components/Common/HeaderComponent.vue'
import { defineAsyncComponent, nextTick } from 'vue'
import { onIdle } from './utils/OnIdle';

export default {
  data() {
    return {
      shouldRender: false
    }
  },
  components: {
    HeaderComp,
    FooterComp: defineAsyncComponent(() => import('./components/Common/FooterComponent.vue'))
  },
  mounted() {
    onIdle(() => {
        this.shouldRender = true;
      });
  }
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  margin-bottom: 64px;
}

.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.6s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
