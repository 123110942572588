import type { DirectiveBinding } from 'vue';
type T = HTMLElement & { clickOutsideEvent: (event: Event) => void };

export default {
    mounted(el: T, binding: DirectiveBinding) {
        el.clickOutsideEvent = function(event: Event) {
            if (!(el === event.target || el.contains(event.target as Node)) && binding.value !== undefined) {
                binding.value(event);
            }
        };
        document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el: T) {
        document.removeEventListener('click', el.clickOutsideEvent);
    },
};