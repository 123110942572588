import { nextTick } from 'vue'

export function onIdle(cb = () => {}) {
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(cb)
  } else {
    setTimeout(() => {
      nextTick(cb)
    }, 200)
  }
}
